<template>
  <div>
    <br>
    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>{{ company.name }}<br />
      {{ company.address1 }}<br />
      {{ company.address2 }}<br />
    </p>
    <p><strong>Vertreten durch:</strong><br />
      Inh.: {{ company.ownerTitle }} {{ company.ownerFirstName }} {{ company.ownerLastName }}</p>
    <br>
    <h2>Kontakt</h2>
    <p>Mobil:  {{ company.phone }}<br />
      E-Mail: {{ company.mailFrom }}</p>
    <br>
    <h2>Steuer Nr:</h2>
    <p>19/231/99509</p>
    <p>Finanzamt Gifhorn</p>
    <br>
    <h2>EU-Streitschlichtung</h2>
    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
    <br>
    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LegalImprint",
  computed: {
    ...mapGetters([
      'company'
    ])
  }
}
</script>