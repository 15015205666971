<template>
    <v-col cols="12" md="12" sm="12">
      <v-card class="card mt-2">
        <v-col cols="12" class="title">
          <v-row class="pa-2">
            <i class="fa-brands fa-apple icon mr-2"/>
            <h3 class="">Apple</h3>
          </v-row>
        </v-col>
        <p class="text-center mt-5 px-2"><b>1.</b> Tippen Sie auf Ihrem (Safari) Browser unten auf das icon</p>
        <v-img class="mx-2" :src="require('@/assets/faq-ios/Schritt 1.jpg')" max-height="80px"/>
        <v-divider class="my-2"/>
        <p class="text-center px-2"><b>2.</b> Zum Home-Bildschirm</p>
        <v-img class="mx-2" :src="require('@/assets/faq-ios/Schritt 2.jpg')" max-height="80px"/>
        <v-divider class="my-2"/>
        <p class="text-center px-2"><b>3.</b> Hinzufügen</p>
        <v-img class="mx-2 mb-7"  :src="require('@/assets/faq-ios/Schritt 3.jpg')" max-height="80px"/>
      </v-card>
    </v-col>
</template>

<script>

export default {
  name: "AppInstall",
}
</script>